<template>
    <v-container fluid>
        <v-data-table
                :headers="headers"
                :items="paginationItems.data"
                :items-per-page="paginationParams.per_page"
                :page="paginationParams.page"
                :server-items-length="paginationItems.total"
                :loading="loading"
                sort-by="id"
                class="border"
                @update:page="changePage"
                @update:items-per-page="changeItemsPerPage"
                :footer-props="footerProps"
                @update:sort-by="sortBy"
                @update:sort-desc="sortDesc"
        >
            <template v-slot:top>
                <v-container>
                    <v-row>
                        <v-col style="min-width: 200px;">
                            <v-text-field
                                    v-model="filters.email"
                                    :label="trans('fields.common.email')"
                                    clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                @click="applyFilters"
                        >{{ trans('buttons.common.apply') }}</v-btn>
                        <v-btn
                                class="ma-2"
                                color="orange"
                                outlined
                                @click="clearFilters"
                        >{{ trans('buttons.common.clear') }}</v-btn>
                        <v-btn
                                class="ma-2"
                                color="info"
                                outlined
                                :href="route('master.users.export.csv')"
                        >{{ trans('buttons.common.exportCsv') }}</v-btn>
                    </v-row>
                </v-container>
            </template>
<!--            <template v-slot:item.actions="{ item }">-->
<!--                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>-->
<!--                <v-icon small color="red" @click="deleteDialogShow(item.id)">mdi-delete</v-icon>-->
<!--            </template>-->
<!--            <template v-slot:item.offer="{ item }">-->
<!--                <offer-link-form-->
<!--                        :offer_id="item.offer_id"-->
<!--                        :pre-search="!item.offer_id ? item.label.substr(0, 3) : ''"-->
<!--                        :label="item.offer ? item.offer.label : trans('manage.offer.notAssigned')"-->
<!--                        :label-class="item.offer ? '' : 'red&#45;&#45;text'"-->
<!--                        :foreign_id="item.foreign_id"-->
<!--                        v-on:updated="initialize"-->
<!--                ></offer-link-form>-->
<!--            </template>-->
<!--            <template v-slot:item.status="{ item }">-->
<!--                <v-icon v-if="item.status" color="green" :title="trans('manage.offer.status.online')">mdi-circle-slice-8</v-icon>-->
<!--                <v-icon v-else color="red" :title="trans('manage.offer.status.offline')">mdi-circle-slice-8</v-icon>-->
<!--            </template>-->
<!--            <template v-slot:item.countries="{ item }">-->
<!--                {{ options.countriesKeyed ? item.countriesArr.map((id) => options.countriesKeyed[id]).join(', ') : '' }}-->
<!--            </template>-->
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | mysqlDate }}
            </template>
            <template v-slot:item.is_demo="{ item }">
                <v-icon v-if="item.is_demo">mdi-check-circle</v-icon>
            </template>
            <template v-slot:item.plan_valid_till="{ item }">
                <template v-if="item.notpayed">
                    {{ trans('pages.plan.unpayed') }}
                </template>
                <template v-else>
                    {{ item.plan_valid_till }}
                </template>
            </template>
            <template v-slot:item.master_autopayment="{ item }">
                <v-switch v-model="item.master_autopayment" @change="setAutopayment(item)"></v-switch>
            </template>

        </v-data-table>
    </v-container>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import tableFilters from "@/mixins/tableFilters";

    export default {
        name: "UserTable",
        components: {
        },
        mixins: [tableWithPagination, tableFilters],
        data() {
            return {
                items: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.email"), value: "email", sortable: false },
                    { text: this.trans("fields.plan.valid_till"), value: "plan_valid_till", sortable: false },
                    { text: this.trans("fields.common.landings"), value: "landings_count", sortable: false },
                    { text: this.trans("fields.common.domains"), value: "domains_count", sortable: false },
                    { text: this.trans("fields.common.links"), value: "links_count", sortable: false },
                    { text: this.trans("fields.common.monthlyViews"), value: "monthlyViews", sortable: false },
                    { text: this.trans("fields.common.isDemo"), value: "is_demo", sortable: false },
                    { text: this.trans("master.autopayment"), value: "master_autopayment", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                filters: {
                    email: '',
                },
                url: this.route('master.users.index'),
            }
        },
        created() {
            // this.loadOptions({languages: true, offerScopes: true, offerTypes: true, countries: true});
        },
        watch: {
        },
        methods: {
            setAutopayment(item){
                if (item.master_autopayment){
                    axios.post(this.route('master.users.setAutopayment', item.id), item).then( () => {
                        this.initialize();
                    }).catch(err => {
                        console.log(err)
                    })
                } else {
                    if (confirm(this.trans('master.confirmAutopay'))){
                        axios.post(this.route('master.users.setAutopayment', item.id), item).then( () => {
                            this.initialize();
                        }).catch(err => {
                            console.log(err)
                        })
                    } else {
                        item.master_autopayment = true;
                    }
                }
            }
        },
        computed: {
            paginationItems: {
                get(){
                    return this.items;
                },
                set(v){
                    this.items = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
